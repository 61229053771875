import React from 'react'

import Typography from '@material-ui/core/Typography'

import Layout from '../../layouts/Article'
import { LinkTab } from '../../components/Link'

const Default = () => {

  const mainView = (
    <Layout
      title="Arturo Escobar and Mauricio Pardo"
      subtitle="Social Movements and Biodiversity on the Pacific Coast of Colombia"
      url='/articles/arturo-escobar-and-mauricio-pardo'
    >

      <Typography
        paragraph variant='subtitle1'
      >
Rediscovering a region: the Colombian Pacific region
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
The Colombian Pacific region is a vast tropical rainforest area around 960 km long, its width fluctuating from 80 to 160 km (roughly 700,000 km2). It extends from Panama to Ecuador, and from the slopes of the western mountain range (Cordillera Occidental) to the Pacific ocean. An approximate 60 per cent of the population inhabits a few cities and large towns, while the remaining population dwells in riverbank areas along the more than 240 rivers flowing from the mountains to the ocean. Afro-Colombians, descendants of slaves brought from Africa at the beginning of the seventeenth century for gold-mining activities, form the majority of the population, although the number of indigenous inhabitants amounts to approximately 50,000. The latter belong primarily to the Embera and Wounan ethnic groups and inhabit the northern part of the department of Chocó. Indigenous communities have maintained specific material and cultural practices, such as multiple economic and survival activities (involving agriculture, fishing, hunting, collecting, and small-scale mining), extended families and kinship relations, strong oral traditions and religious practices, particular forms of knowledge, and the use of diverse ecosystems, etc.
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
The black groups maintain and have developed cultural practices of African as well as of indigenous and Spanish origin: complex systems of rainforest utilization, extended families, special dances, oral and musical traditions, funerary rituals, witchcraft, etc. These activities are increasingly articulated with modern urban forms, due in part to internal and external migrations as well as to the impact of commodities, the mass media, and development programs. Although the region has never been isolated from world markets-gold and platinum bonanza cycles, precious woods, rubber, and timber industries (Whitten, 1986), and, as we soon will see, genetic resources, have linked the ethnic communities to the world economy—it was only during the 1980s that the region began to be taken into consideration and that policies were devised for its development. 
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
What is now taking place in the Pacific region is unprecedented: large scale development plans, new fronts opening for capital accumulation (such as African palm tree plantations and shrimp nurseries), and numerous indigenous and black mobilizations. Within this emerging imaginary, the Colombian Pacific region occupies an important place as a launching platform for the macroeconomy of the future (Escobar, 1996). The discovery of biodiversity in this region is one of the main components of this new imaginary. 
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
Three main actors—the state, capital, and social movements—struggle to define the future of the region. Behind these actors stand different political and cultural orders whose genealogies and whose connections with socioeconomic and cultural rationales must be clarified. The study of each actor's cultural politics is important because the future of the region will depend, in great measure, on the ways in which it will be defined and represented. The cultural politics concerning nature in this region is based on three fundamental processes, which were simultaneously developed after 1990: 1) radical policies of opening to world markets, favored by the government in recent years, with special emphasis on the integration of Pacific-basin economies into those of the rest of the country; 2) new sustainable development strategies and biodiversity conservation; 3) more numerous and increasingly visible mobilizations among black and indigenous populations.
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
The present situation in the Colombian Pacific region is very special because the various factors involved in the debates about biodiversity, patents, and intellectual property rights over the use of biological species are deeply interconnected here. This area has been acknowledged as one of the most biodiverse in the world (Garda Kirkbride, 1986); its rural zones constituted by rainforests are inhabited by ethnic indigenous and Afro-Colombian groups deeply engaged in organizing themselves in defense of their rights. Moreover, the fact that the Colombian Constitution and Colombian laws acknowledge the rights of ethnic groups, and that Colombia officially participates in international fora about biodiversity, make the Colombian Pacific region one of the most crucial arenas for contemporary debates about biodiversity. 
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
Since the late 1980s, the government has been pursuing a wide-ranging policy of integration with other economies of the Pacific basin. The Pacific Ocean-renamed "the sea of the twenty-first century"—is perceived as the socio-economic, and to a lesser extent cultural, space of the future. 
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
Ethnic mobilization in the Pacific region
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
Since the early 1970s, indigenous groups from all over the world, especially Colombia and elsewhere in Latin America, have renewed with particular intensity their struggle for survival. They now demand specific rights based on ethnic difference and no longer solely as agrarian social sectors in search of basic rights. These mobilizations have had an impact on multilateral arenas such as UNESCO, the ILO, and the UN. In particular, ILO agreement #169 involved a detailed acknowledgement of the rights of indigenous peoples of the world, and was sanctioned as law by many of the signatory countries. This was the case in Colombia, with the approval of Law 21 of 1991. However, as Florez (2000: 5) notes, there is no national legislation or international agreements for the explicit protection of traditional knowledge from undue appropriation by multinationals buttressed by the governments of capitalist metropolises. The regulation of such activities is thus one of the urgent issues that ethnic movements see the need to target. 
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
The irruption of the concept of biodiversity and its varied consequences has taken place in Colombia within a context of three decades of indigenous struggle and one decade of black community struggle for the collective ownership of their territory, respect towards their socio-cultural particularities, and autonomy of indigenous authorities. As a result of this collective mobilization of indigenous and black communities, Colombian legislation, by way of different constitutional regulations, laws, and decrees, now acknowledges these rights. But the ability of the Colombian state to enforce its own laws is extremely weak. 
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
Indigenous peoples from the region are the minority group. Numbering around 50,000, they are almost all located in the northern portion of the department of Chocó. A much smaller group is based in the southern departments. In Chocó, black inhabitants have been organized since 1980 through the Embera Wounan Regional Organization (Organización Regional Embera Wounan, or OREWA). OREWA includes 150 local community councils or governments (cabildos). In the southern portion of the Pacific coastal forests there are much smaller regional organizations made up of Embera groups, known in the region as eperara siepidara. There are around 7000 Embera organized under the Antioquia Indigenous Organization (Organización lndigena de Antioquia, or OIA) and in the larger communities of the Charni area in Risaralda. They inhabit the slopes of the mountainous range adjacent to the northern part of the Pacific coastal plain, in the departments of Antioquia and Risaralda, areas that have been the object of intensive colonization for almost a century. In the space between the mountain range and the Pacific jungle, in the Department of Nariño, around 5000 members of the Awa community are organized around the Union of the Awa People (Union del Pueblo Awa, or UNIPA). 
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
The organization of rural populations from the Pacific started to develop twenty years ago. At the time, a group of young indigenous high school students, supported by Catholic missionaries and inspired by the emerging organizations of indigenous peoples from the Cauca department in Colombia and other parts of Latin America, created the Embera Wounan Regional Organization of Chocó. This organization engaged in a struggle for the recognition of indigenous territories, respect for their culture, and the organizational linkage of all indigenous communities in the region. Two decades later, OREWA successfully registered property titles in reservations corresponding to the majority of indigenous lands and established local councils (cabildos) in almost all of the indigenous communities in Chocó (Pardo, 1997: 233). Throughout, OREWA has had to confront timber companies, mining businesses, road construction, hydroelectric projects, and, more recently, attempts to develop research on biological and genetic resources without previous consultation. The above-mentioned processes of organization in the areas adjoining the mountain range in the departments of Antioquia, Risaralda, and Nariño, as well as in the lowland coastal forests of Valle, Cauca, and Nariño, are somewhat more recent. 
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
In the early 1980s, black population groups of the Atrato River, organized under the Atrato Integral Peasant Association, or ACIA (Asociación Campesina Integral del Atrato), involved the association, with missionary support, in the defense of their territory and natural resources against large timber companies, which had obtained great concessions from the state. After intense mobilization and tortuous negotiation with the government, ACIA achieved the acknowledgment of the communities' right to access and protection of enormous territorial extensions vital for their survival, and which previously had been considered wastelands. Only in recent years has the banner of cultural difference become the most important element of this black organization, particularly as a result of a new movement in the Pacific region. In this sense, there are two main factors: first, the developmental and capitalist assault on the region, fueled by the process of economic opening and the region's integration into the country; and second, the constitutional reform process that culminated in the election of the National Constituent Assembly and the replacement of the Constitution of 1886. 
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
For the black communities of the Pacific, this was a unique opportunity to construct their identity according to cultural, political, and socioeconomic demands and proposals. Given the fact that blacks did not succeed in securing their own representatives to the 1991 Constituent Assembly, their situation was presented by the indigenous representatives. The cultural and territorial rights of black communities were finally included in Law 70 of 1993, two years after the promulgation of the new Political Constitution. 
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
ACIA's experience in the middle Atrato region and its demands for the recognition of the collective character of the territory and its cultural distinctiveness were crucial in the process that led to the recognition of the rights of black populations in the 1991 Constitution. They were also fundamental for the emergence of a dynamic that led to the organization of the black communities in the whole Pacific area. In Chocó, shortly after ACIA, other organizations began to form along other rivers, such as the Baudó, the upper and lower San Juan, and the lower Atrato. Later on, as a sequel to Constituent Assembly discussions, activist organizations emerged in the greater urban centers with the aim of stimulating the organization of black rural communities within departments. 
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
The new constitution gave unprecedented rights to ethnic and religious minorities and explicitly recognized specific rights for indigenous peoples, granting them unalienable territories in the form of reservations and recognizing their political autonomy. The constitutional change provided a new context for a series of social processes, black and indigenous organizations being the most visible among these. The new constitution ordered the promulgation of a law for the safeguarding of the territories, society, and culture of the black communities in the rainforests of the Pacific and similar areas. As a consequence, the government presented Law 70 of 1 993, which was approved. This law established the creation of collective territories for the black communities of the Pacific, their administration by community councils, and other measures for the protection of the culture and society of these social groups. 
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
The actions undertaken by black populations ten years ago with the aims of presenting their case to the Assembly, exerting pressure for the promulgation of Law 70, and participating in their own development, have extended the organizational process to the majority of black populations of the Pacific. These started as nuclei of urban activists, which disseminated information and educated black river-dwellers on the possibility of securing their territories as well as their own cultures. Later, in 1992, in order to promote debate on legislation related to black communities, the government provided resources for organizing workshops in the whole Pacific area. These resources were mainly awarded to incipient organizations in the departments, a fact that allowed them to consolidate their influence. 
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
Mobilization and discussion during the Constituent Assembly (1990- 1991) in preparation for the drafting of Law 70 (1992 and 1993) and its later disclosure were financed by the government, with significant leadership on the part of some fluvial basin organizations from Chocó and organizations from the departments of Valle, Cauca, and Nariño. In the latter, between 1993 and 1998, the Process of Black Communities—PCN—a network made up of more than 140 local organizations, played a leading role in the struggle for constitutional rights for black communities and the defense of their territories. The PCN's most distinctive feature is the articulation of a political proposal whose character and base are mainly ethno-cultural. Their vision is not that of a movement based on a catalogue of "needs" and demands for "development" but that of a struggle presented in terms of defending cultural differences. Therein lies its most radical character. 
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
The PCN coordinated departmental organizations known as palenques in Valle, Cauca, Nariño, and on the Atlantic coast. With the advancement of the process of registration of collective territories, the influence of the PCN at the national level and that of the palenques at the departmental level has diminished as community councils consolidate. 
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
At the third National Assembly of Black Communities (Asamblea Nacional de Comunidades Negras), where the PCN was formally constituted in September of 1993, the participants proposed goals such as the following: 1) reaffirming identity (the right to be black); 2) the right to the land; 3) autonomy, especially in the realm of politics; 4) the right to construct an autonomous perspective of the future based on black culture (Grueso et al., 1998).
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
Considerations about biodiversity and the rights of local populations over the region's biological and genetic resources occupy a prominent position in the mobilization agendas of both indigenous and black organizations of the Pacific. Very important among contemporary anti-hegemonic expressions, the ethnic-territorial movements of the Pacific, be they Amerindian or black, have succeeded in removing vast territories from private, individual, and mercantile land property regimes and in placing them under local population control for sustainable use. 
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
The struggle of these groups for controlling their territory and their natural resources has developed on several fronts: against the crudest extraction methods such as strip-mining and forest devastation; against infrastructure work such as roads, ports, and hydroelectric plants threatening community welfare; against capitalist forms of agro-industrial exploitation that endanger ecological viability or access to land (such as shrimp nurseries, palm tree plantations, and palmetto trunk heart exploitation); against attempts to explore genetic resources on the part of multinational companies; or against state regulations (such as in the case of access to mangrove areas and their inclusion in collective territories or the viability of including artisan mining in the mining code). 
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
Thus, these movements have been struggling against different forms of exclusion, domination, and exploitation. Analogous to what Santos and Santos (2000: 1 8) observe, the movements have achieved creative results regarding the contradictions that emerged in the process of collective action, and have developed emancipatory relationships while confronting established power in different situations. 
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
For activists, the defense of particular cultural practices of riverbank communities is a strategic decision, inasmuch as these practices are seen not only as forms of resistance against capitalism, but also as elements of an alternative ecological rationality. In this way, the movement is constructed on the basis of networks of cultural practices and meanings that are deeply rooted in riverbank communities and are part of their active construction of worlds (Melucci, 1989). Nevertheless, the movement conceives these networks as bases for the political configuration of an identity related to the encounter with modernity-the state, capital, science, biodiversity-rather than to timeless essences. 
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
As prescribed by Law 70 (1993), the registration of Black Collective Territories in the Pacific has been taking place since 1997. The first title was granted to some localities of the Lower Atrato river in March of 1997; in February of 1998, the government gave ACIA the collective ownership of around 650,000 hectares in the Mid Atrato. Since then, smaller collective territories in different parts of the Pacific area have been legalized, in a process that has considerably changed organizational patterns in the three southern departments. The leadership of urban activists from the main towns and cities of the Pacific (Buenaventura, Guapi, and Tumaco) has progressively receded to give way to the community councils created by Law 70 as collegiate administrators of collective territories. 
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
Most of these community councils have formed in sub-regional organizations, which, although they have not explicitly given up departmental organization, have gradually gained independence and autonomy. As collective property is legalized, urban activists lose their influence on rural communities, in spite of the fact that they are still quite visible and maintain communication with governmental institutions. Thus, the organizing pattern that has prevailed in northern Chocó since the mid-1980s, when the movement started — sub-regional associations of adjacent riverbank communities-joined in the struggle for collective territory—is gradually consolidating in the southern part of the Pacific region (Pardo, 2000: 339). This is what activists and analysts of the black community movement have labeled "ethnic-territorial organizations." 
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
Indigenous and black movements are thus involved in complex networks of national and international relations that help them deal with the isolation and anomie that the hegemonic system has imposed on them. Not only have they joined networks of anti-hegemonic globalization, but also several alliances and strategic coalitions have been implemented among black and indigenous organizations. Since indigenous communities began organizing seven years before black populations, this previous experience enabled them to support emerging black organizations in 1987, particularly ACIA. Shortly thereafter, in 1989, ACIA and OREWA promoted the creation of the Peasant Association of the San Juan River (ACADESAN), which led to the first meeting between black and indigenous communities. As a result of this meeting, the bold idea of creating an inter-ethnic collective territory was proposed (OBAPO, OREWA, and ACIA, 1 990-1991). OREWA and other black organizations continued coordinating mobilizations to the point of jointly supporting the indigenous candidate of the Chocó region to the Constituent Assembly of 1991. The indigenous representative was elected and had a significant role in securing the titling of collective territories for black populations (Wade, 1995). Currently, in the Chocó region, these organizations continue coordinating their positions regarding certain issues, as well as their participation in public entities as mandated by legislation. 
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
In the region to the south of Chocó, the indigenous population is considerably smaller and their organizations more recent. In this area, black organizations have formed in departmental federations (the so-called palenques), many of which are coordinated at the national level by the PCN (Process of Black Communities). These organizations' joint actions have recently produced important results regarding demands to include mangrove areas in the collective titles. The Ministry of the Environment argued that mangrove areas were considered public and therefore could not be part of collective titles. However, the organizations stated that the public status of the mangroves was violated when the state itself permitted shrimp companies to construct gigantic pools that seriously altered the mangrove ecology. Finally, in 2000, the government accepted the demand to include the mangroves in the titles, although not as unalienable property. In claiming control over mangrove areas, the movements continue to broaden their conception of nature-territory as a vital space of complex and varied interactions between populations and the environment, therefore fighting against the increasing incursions of capital to commodify nature. 
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
Indigenous organizations form part of the Colombian National Indigenous Organization (ONIC), which maintains active and permanent international contacts with other ethnic, human rights, and environmental organizations that support grassroots movements. Black organizations have also established relations with international entities. International committees, mainly European, have visited the areas struck by war and violence. Both indigenous and black organizations have developed projects funded by international organizations from different countries. European Catholic organizations have been constant sponsors of indigenous organizations and of ACIA. Both black and indigenous leaders regularly participate in international forums and voice their positions. PCN activists, for example, have attended numerous international meetings, as part of both anti-globalization networks as well as black and environmental activist networks (Escobar, 2000). More recently, black and indigenous movements have been forced to appeal to all possible national and international contacts to seek solidarity and support in view of the brutal impact of war on the populations and territories of the region.
      </Typography>

      <br /><br /><br /><br />
      <Typography 
        paragraph
        style={{fontSize: '14px'}}
      >
Arturo Escobar and Mauricio Pardo, Social Movements and Biodiversity on the
 Pacific Coast of Colombia (excerpt), in Boaventura de Sousa Santos (ed.),
  Another Knowledge is Possible: Beyond Northern Epistemologies. 
  Verso. pp. 288-314 (2007). Via {' '}
  <LinkTab
        color='inherit'
        href="http://www.boaventuradesousasantos.pt/media/Chapter%2011(2).pdf"
      >
        http://www.boaventuradesousasantos.pt/media/Chapter%2011(2).pdf
      </LinkTab> 
      </Typography>
      {/* <Typography 
        paragraph
        style={{fontSize: '14px'}}
      >
      <LinkTab
        color='inherit'
        href="https://static.skllchain.com/vilem-flusser-exile-and-creativity.pdf"
      >
        Download
      </LinkTab> 
      </Typography> */}

    </Layout>
  )

  return mainView
}

export default Default
